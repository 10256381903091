export const getFilenameParts = (filename) => {
  const parts = /(.+)\.(.+)$/.exec(filename);
  return [
    parts && parts[1] ? parts[1] : filename,
    parts && parts[2] ? parts[2] : "",
  ];
};

export const readFileAsBuffer = (ff) => {
  return new Promise((resolve, reject) => {
    const rr = new FileReader();
    rr.onload = (e) => resolve(e.target.result);
    rr.onerror = (e) => reject('Error : ' + e.type);
    rr.readAsArrayBuffer(ff);
  });
};

export const readFileAsString = (ff) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = (e) => reject('Error : ' + e.type);
    reader.readAsBinaryString(ff);
  })
};

export const downloadPdfFile = (file, affix, name) => {
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(file);
  const [filename, fileext] = getFilenameParts(name || file.name);
  link.download = `${filename}${affix || ''}.${fileext}`;
  link.click();
};
