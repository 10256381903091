import React from 'react';
import { Button, Icon } from './styles';
import addCertificate from '../../resources/svgs/addCertificate.svg';
import addAgreement from '../../resources/svgs/addAgreement.svg';
const FileUploader = ({ onChange, text, type = 'agreement', ...props }) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  if (text.length > 18) {
    text = text.slice(0, 18) + '...';
  }
  return (
    <>
      <Button onClick={handleClick}>
        <Icon src={(type === 'agreement' ? addAgreement : addCertificate)} />
        {text}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={event => onChange(event.target.files)}
        style={{ display: 'none' }}
        {...props}
      />
    </>
  );
};

export default FileUploader;
