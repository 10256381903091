import styled from 'styled-components';

export const Button = styled.button`
  font-family: 'CeraL';
  height: 60px;
  width: 300px;
  background-color: rgb(95, 171, 195);
  font-size: 21px;
  font-family: Roboto;
  letter-spacing: 1px;
  font-weight: 300;
  color: white;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  :focus {
    outline: none;
  }
  :hover {
    color: white;
    background-color: rgb(95, 171, 195);
    transition: 0.3s;
  }
`;

export const Icon = styled.img`
  height: 25px;
  margin-right: 10px;
`;
