import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Header = styled.div`
  height: 15vh;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #284c61;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 75vh;
  // margin: 40px 0 15vh 0;
`;

const Footer = styled.div`
  height: 10vh;
  width: 100%;
  @media (max-height: 768px) {
    position: relative;
  }
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  background-color: #284c61;
  justify-content: center;
  align-items: center;
  a {
    color: white;
    font-size: 19px;
    font-family: Roboto;
    letter-spacing: 1px;
    font-weight: 300;
    text-align: center;
    :hover {
      text-decoration: none;
    }
  }
`;



export { Container, Header, Content, Footer };
