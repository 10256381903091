import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAlert } from 'react-alert';

import Spinner from '../FullSpinner';
import SignForm from './SignForm';
import { Button, SignatoryContainer } from './styles';
import {
  getMonaxDocuments,
  sendMonaxSignedDocument,
  signMonaxDocument,
} from '../../services/monaxService';
import { cancelAblePromise } from '../../services/axiosCall';

import { MONAX_APP_URL } from '../../config';

const GENERIC_ERROR =
  'Algo salió mal al obtener su documento. Intente nuevamente más tarde';

const MonaxSigner = ({ agreementId, agreementRedirectUrl, organizationAddress}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [signedDocument, setSignedDocument] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [document, setDocument] = useState('');
  const alert = useAlert();

  const {
    isLoading: authLoading,
    isAuthenticated,
    error: authError,
    loginWithRedirect,
    logout,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  const setMonaxContext = () => {
    let cPr;
    if (!document) {
      setLoading(true);
      cPr = cancelAblePromise(
        getMonaxDocuments(getAccessTokenSilently, agreementId, organizationAddress)
      );
      cPr.promise
        .then(document => {
          if (!document) {
            return setError(GENERIC_ERROR);
          }
          setDocument(document);
        })
        .catch(err => {
          console.log('Error getting documents= ', err);
          setError(GENERIC_ERROR);
        })
        .then(() => {
          setLoading(false);
        });
    }
    return () => {
      if (cPr) {
        cPr.cancel();
      }
    };
  };

  useEffect(() => {
    let cPr1;
    let cPr2;
    if (signedDocument) {
      setLoading(true);

      cPr1 = cancelAblePromise(
        sendMonaxSignedDocument(
          getAccessTokenSilently,
          agreementId,
          organizationAddress,
          signedDocument,
          document.key,
          document.meta,
          document.version
        )
      );
      cPr1.promise
        .then(() => {
          cPr2 = cancelAblePromise(
            signMonaxDocument(
              getAccessTokenSilently,
              agreementId,
              organizationAddress,
              signedDocument,
              user.name
            )
          );
          return cPr2.promise.then(() => {
            setCompleted(true);
          });
        })
        .catch(err => {
          console.log('Error sending document= ', err);
          setError(GENERIC_ERROR);
        })
        .then(() => {
          setLoading(false);
        });
    }
    return () => {
      if (cPr1) {
        cPr1.cancel();
      }
      if (cPr2) {
        cPr2.cancel();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedDocument]);

  useEffect(() => {
    if (completed && agreementId) {
      alert.success('Su documento ha sido firmado con éxito');
      const timerH = setTimeout(() => {
        if (agreementRedirectUrl) {
          window.location = `${MONAX_APP_URL}/agreements/${agreementId}`;
        } else {
          window.location = agreementRedirectUrl;
        }
      }, 3000);
      return () => {
        clearTimeout(timerH);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed, agreementId]);

  useEffect(() => {
    if (isAuthenticated) {
      return setMonaxContext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (loading || authLoading) {
    return (
      <SignatoryContainer>
        <Spinner />
      </SignatoryContainer>
    );
  }

  if (completed) {
    return (
      <div className="align-self-center">
        <span className="alert alert-success text-muted">
          Su firma se ha realizado con éxito. Redirigiendo a Monax ...
        </span>
      </div>
    );
  }

  return (
    <>
      <SignatoryContainer>
        {!isAuthenticated && (
          <Button
            type="button"
            onClick={() =>
              loginWithRedirect({
                appState: {
                  agreementAddress: agreementId,
                  organizationAddress: organizationAddress,
                }
              })
            }
          >
            Autenticarse
          </Button>
        )}
        {(error || authError) && (
          <div className="mt-3 d-flex justify-content-center">
            <span className="alert alert-danger text-muted">
              {error || authError.message}
            </span>
          </div>
        )}
        {isAuthenticated && document && (
          <div className="sign-form">
            <SignForm
              preloadedDocument={document}
              onError={err => {
                alert.error(err, { timeout: 2000 });
              }}
              onLoading={setLoading}
              onDocumentSigned={doc => setSignedDocument(doc)}
            />
          </div>
        )}
        {isAuthenticated && (
          <div className="logout">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                logout({ returnTo: window.location.origin });
              }}
            >
              Salir
            </button>
            {/* <a style={{ marginLeft: 10 }} onClick={() => setMonaxContext()}>Get document</a> */}
          </div>
        )}
      </SignatoryContainer>
    </>
  );
};

export default MonaxSigner;
