import React, {useState} from 'react';
import MonaxSigner from './MonaxSigner';
import MonaxConfigurer from './MonaxConfigurer';
import StandaloneSigner from './StandaloneSigner';
import {MONAX_AUDIENCE, MONAX_CLIENT_ID, MONAX_LOGIN_HOSTNAME} from "../../config";
import {Auth0Provider} from "@auth0/auth0-react";

const Signatory = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [agreementId, setAgreementId] = useState(urlParams.get('agreementAddress'));
  const [organizationAddress, setOrganizationAddress] = useState(urlParams.get('organizationAddress'));
  const agreementRedirectUrl = decodeURIComponent(urlParams.get('redirectURL'));

  let monaxFlow = false;
  let monaxConfigure = false;
  if (window.location.pathname.indexOf('callback') !== -1) {
    monaxFlow = true;
    if (window.location.pathname.indexOf('organizations') !== -1) {
      monaxConfigure = true;
    }
  }
  if (agreementId) {
    monaxFlow = true;
  }
  if (monaxFlow) {
    return (
      <Auth0Provider
        domain={MONAX_LOGIN_HOSTNAME}
        clientId={MONAX_CLIENT_ID}
        redirectUri={`${window.location.origin}/callback`}
        audience={MONAX_AUDIENCE}
        cacheLocation="localstorage"
        onRedirectCallback={appState => {
          setAgreementId(appState.agreementAddress);
          setOrganizationAddress(appState.organizationAddress);
          let searchParams = new URLSearchParams({});
          searchParams.set('agreementAddress', appState.agreementAddress);
          searchParams.set('organizationAddress', appState.organizationAddress);
          window.location.search = searchParams.toString();
        }}
      >
        {monaxConfigure ? <MonaxConfigurer /> : (
          <MonaxSigner organizationAddress={organizationAddress} agreementId={agreementId} agreementRedirectUrl={agreementRedirectUrl} />
        )}
      </Auth0Provider>
    );
  } else {
    return <StandaloneSigner />;
  }
};

export default Signatory;
