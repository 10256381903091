import styled from 'styled-components';

const Footer = styled.div`
  flex: 0 0 auto;
  height: 9vh;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #284c61;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  flex: 0 1 auto;
`;

const Button = styled.button`
  margin: 0.2em;
  height: 50px;
  width: 150px;
  background-color: transparent;
  font-size: 21px;
  font-family: Roboto;
  letter-spacing: 1px;
  font-weight: 500;
  color: rgb(95, 171, 195);
  border-radius: 25px;
  border: solid 3px rgb(95, 171, 195);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  :focus {
    outline: none;
  }
  :hover {
    color: white;
    background-color: rgb(95, 171, 195);
    transition: 0.3s;
  }
`;

export { Footer, Button, Container };
