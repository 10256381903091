import React, { useMemo, useState, useRef, useCallback, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import './index.scss';
import stamp from './stamp.png';

const options = {
  // cMapUrl: 'cmaps/',
  // cMapPacked: true,
};

function canvaCoord2PdfCoord([xC, yC], pdfMediaBox, canvas) {
  const ratio = canvas.width / Number(pdfMediaBox.width);
  return [
    xC / ratio,
    (canvas.height - yC) / ratio,
  ];
}

function pdfCoord2CanvaCoord([xP, yP], pdfMediaBox, canvas) {
  const ratio = canvas.width / Number(pdfMediaBox.width);
  return [
    Number(xP) * ratio,
    (Number(pdfMediaBox.height) - Number(yP)) * ratio
  ];
}

function pdfDimensions2CanvaDimensions(dimm, pdfMediaBox, canvas) {
  const ratio = canvas.width / Number(pdfMediaBox.width);
  return [dimm[0] * ratio, dimm[1] * ratio];
}

function canvaDimensions2PdfDimensions(dimm, pdfMediaBox, canvas) {
  const ratio = canvas.width / Number(pdfMediaBox.width);
  return [dimm[0] / ratio, dimm[1] / ratio];
}

function onMousePosition(canvas, event, doAction, stampDimensions) {
  const rect = canvas.getBoundingClientRect();
  // if ((rect.right - rect.left) !== canvas.width) {
  //   throw new Error(`WIDTH: ${rect.right - rect.left} is not same as ${canvas.width}`);
  // }
  // if ((rect.bottom - rect.top) !== canvas.height) {
  //   throw new Error(`HEIGHT: ${rect.bottom - rect.top} is not same as ${canvas.height}`);
  // }
  let x =
    ((event.clientX - rect.left) / (rect.right - rect.left)) * canvas.width;
  if (x > canvas.width - stampDimensions[0]) {
    x = canvas.width - stampDimensions[0];
  }
  let y =
    ((event.clientY - rect.top) / (rect.bottom - rect.top)) * canvas.height;
  if (y > canvas.height - stampDimensions[1]) {
    y = canvas.height - stampDimensions[1];
  }
  return doAction(
    [x, y]
  );
}

function stampSignature(
  coordinates,
  prevState,
  canvas,
  onSavePrevState,
  stampImg,
  stampDimensions,
) {
  const ctx = canvas?.getContext('2d');
  if (prevState) {
    const { img: prevData, coords: prevCoordinates } = prevState;
    ctx.putImageData(prevData, prevCoordinates[0], prevCoordinates[1]);
  }
  const prevImageData = ctx.getImageData(
    coordinates[0],
    coordinates[1],
    stampDimensions[0],
    stampDimensions[1],
  );
  onSavePrevState({ img: prevImageData, coords: coordinates });
  ctx.beginPath();
  ctx.drawImage(stampImg, coordinates[0], coordinates[1]);
}

function clickHandlerFactory(
  valuePrevState,
  setPrevState,
  onChangeCoordinates,
  stampImg,
  stampDimensions,
  pdfMediaBox,
) {
  return ev => {
    onMousePosition(ev.target, ev, (coords) => {
      stampSignature(coords, valuePrevState, ev.target, setPrevState, stampImg, stampDimensions);
      if (onChangeCoordinates) {
        onChangeCoordinates(coords, ev.target);
      }
    }, canvaDimensions2PdfDimensions(stampDimensions, pdfMediaBox, ev.target));
  };
}

function refHandlerFactory(
  cleanUpFunctionRef,
  valuePrevState,
  setPrevState,
  onChangeCoordinates,
  stampImg,
  stampDimensions,
  pdfMediaBox,
) {
  return nodeEl => {
    if (!nodeEl) {
      return () => {};
    }
    if (!nodeEl.classList.contains('w-stamp-pointer')) {
      nodeEl.classList.add('w-stamp-pointer');
    }
    if (cleanUpFunctionRef.current) {
      cleanUpFunctionRef.current();
    }
    const clickHandler = clickHandlerFactory(
      valuePrevState,
      setPrevState,
      onChangeCoordinates,
      stampImg,
      stampDimensions,
      pdfMediaBox,
    );
    nodeEl.addEventListener('mousedown', clickHandler);
    cleanUpFunctionRef.current = () => {
      nodeEl.removeEventListener('mousedown', clickHandler);
    };
  };
}

function changePage(offset, setPNumber) {
  setPNumber(prevPageNumber => prevPageNumber + offset);
}


function loadStampImage() {
  const img = new window.Image();
  img.src = stamp;
  return img;
}

const STAMP_IMAGE = loadStampImage();
const STAMP_WIDTH = 101;
const STAMP_HEIGHT = 37;

export default function ClickAblePdf({
  bodyStyle,
  footerStyle,
  file,
  onChangeCoordinates,
  onChangePageNumber,
  pdfMediaBox,
  initialStamps,
}) {
  const [valueNumPages, setNumPages] = useState(null);
  const [valuePageNumber, setPageNumber] = useState(1);
  const [valuePrevState, setPrevState] = useState(null);
  const cleanUpFunctionRef = useRef(null);

  useEffect(() => {
    cleanUpFunctionRef.current = null;
    setPrevState(null);
    setPageNumber(1);
  }, [setPrevState, file]);

  useEffect(() => {
    onChangePageNumber(valuePageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuePageNumber]);

  const nextPage = useCallback(() => {
    setPrevState(null);
    changePage(1, setPageNumber);
  }, [setPageNumber]);

  const previousPage = useCallback(() => {
    setPrevState(null);
    changePage(-1, setPageNumber);
  }, [setPageNumber]);

  const onDocumentLoadSuccess = useCallback(({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  }, []);

  const initialStampsMemo = useMemo(
    () => new Map((initialStamps || []).map(({page, coordinates}) => [page, coordinates])),
    [initialStamps?.map(({page, coordinates: [x, y]}) => `${page}-${x}-${y}`).sort().join('#')]
  );
  const pdfMediaBoxMemo = useMemo(
    () => pdfMediaBox,
    [pdfMediaBox?.width, pdfMediaBox?.height]
  );
  const onDocumentRenderSuccess = useCallback(
    () => {
      if (!pdfMediaBoxMemo?.width || !pdfMediaBoxMemo?.height) {
        return;
      }
      const canvas = document.querySelector('.import-pdf-page canvas');
      const ctx = canvas?.getContext('2d');
      (initialStampsMemo.get(valuePageNumber) || []).forEach(coordinate => {
        const pdfC = pdfCoord2CanvaCoord(coordinate, pdfMediaBoxMemo, canvas);
        // ToDo: Is ignoring stamp dimensions!
        ctx.drawImage(
          STAMP_IMAGE,
          pdfC[0],
          pdfC[1],
        );
      });
    },
    [pdfMediaBoxMemo, initialStampsMemo, valuePageNumber]
  );

  const refHandler = useCallback(
    refHandlerFactory(
      cleanUpFunctionRef,
      valuePrevState,
      setPrevState,
      (coords, canvas) => onChangeCoordinates(canvaCoord2PdfCoord(coords, pdfMediaBox, canvas)),
      STAMP_IMAGE,
      [STAMP_WIDTH, STAMP_HEIGHT],
      pdfMediaBoxMemo,
    ),
    [valuePrevState, setPrevState, onChangeCoordinates, pdfMediaBoxMemo]
  );

  return (
    <div className="d-flex flex-column align-items-center py-1">
      <div
        style={{ width: 'fit-content', maxWidth: '100%' }}
        className="d-flex flex-column align-items-center"
      >
        <div style={bodyStyle}>
          <Document
            file={file}
            key={file}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
            renderMode="canvas"
          >
            <Page
              className="import-pdf-page"
              onRenderSuccess={onDocumentRenderSuccess}
              pageNumber={valuePageNumber}
              canvasRef={refHandler}
              renderMode="canvas"
              renderTextLayer={false}
            />
          </Document>
        </div>
        <div
          className="py-2 w-100 d-flex justify-content-between"
          style={footerStyle}
        >
          <button
            type="button"
            disabled={valuePageNumber <= 1}
            onClick={previousPage}
            style={{
              backgroundColor: 'rgb(95,171,195)',
              border: 'none',
              height: 'fit-content',
            }}
            className="btn btn-primary"
          >
            Previous
          </button>
          <p className="text-center">
            Page {valuePageNumber || (valueNumPages ? 1 : '--')} of{' '}
            {valueNumPages || '--'}
          </p>
          <button
            type="button"
            disabled={valuePageNumber >= valueNumPages}
            onClick={nextPage}
            style={{
              backgroundColor: 'rgb(95,171,195)',
              border: 'none',
              height: 'fit-content',
            }}
            className="btn btn-primary"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
