import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppLayout from './components/Layout';
import Signatory from './components/Signatory';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertMUITemplate from 'react-alert-template-basic';
import './resources/fonts/fonts.css';

const options = {
  position: positions.BOTTOM_CENTER,
  transition: transitions.SCALE,
};

// WARNING: Have to use cacheLocation="localstorage" because an issue on safary. More info at:
// https://community.auth0.com/t/error-login-required-for-getaccesstokenslienlty-in-auth0-react-in-safari-and-incognito-windows/57073
ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertMUITemplate} {...options}>
      <AppLayout>
        <Signatory />
      </AppLayout>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
