import styled from 'styled-components';
import React, { useState } from 'react';

export const Button = styled.button`
font-family: 'CeraM';
  height: 50px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-family: Roboto;
  letter-spacing: 1px;
  font-weight: 400;
  background-color: #284c61;
  border-radius: 10px;
  color: white;
  border: none;
  transition: 0.3s;
  margin-top: 40px;
  :focus {
    outline: none;
  }
  :hover {
    background-color: #376d8c;
    transition: 0.5s;
  }
`;

export const Title = styled.h3`
  margin: 0 0 60px 0;
  padding: 0;
  font-size: 1.8rem;
  font-family: Roboto;
  font-weight: 300;
  color: #284c61;
  text-align: center;
`;

export const Input = styled.input`
  height: 45px;
  width: 300px;
  font-size: 1rem;
  font-family: Roboto;
  letter-spacing: 1px;
  font-weight: 300;
  background-color: white;
  border-radius: 10px;
  color: rgb(95, 171, 195);
  border: solid 1px rgb(95, 171, 195);
  :focus {
    outline: none;
  }
`;

const EyeIcon = styled.i`
  position: absolute;
  color: rgb(95, 171, 195);
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
`;

export const InputPassword = props => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="position-relative">
      <Input {...props} type={showPassword ? 'text' : 'password'} />
      <EyeIcon
        id="icon"
        onClick={() => setShowPassword(!showPassword)}
        className={showPassword ? 'fa fa-eye' : 'fas fa-eye-slash'}
      ></EyeIcon>
    </div>
  );
};

export const SignReasonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  `;

export const P = styled.p`
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 1rem;
  font-family: Roboto;
  font-weight: 300;
  font-style: italic;
  color: #284c61;
  text-align: center;
  `;

// ToDo: Dont use class selectors inside styled componets (case of logout). Dont use child or successor selector either
export const SignatoryContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  
  .sign-form{
    padding: 30px 30px;
    border-radius: 8px;
    box-shadow: 0px 5px 8px 0px rgba(0,0,0,0.4);
  }
  .logout {
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    font-family: 'Roboto';
    font-weight: 300;

    a {
      color: #4270a2;
    }
  }
`;

export const DocumentIcon = styled.img`
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 60px;
  margin-bottom: 10px;
  opacity: ${props => (props.uploaded ? 1 : 0.5)};
`;
