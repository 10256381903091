import React from 'react';
import { Footer, Container } from './styles';
import * as ReactModal from 'react-modal';
import { X } from 'react-bootstrap-icons';
import LoadingButton from '../LoadingButton';

const Modal = ({ children, showModal, onClick, onHide }) => {
  const customStyles = {
    content: {
      display: 'flex',
      flexDirection: 'column',
      top: '50%',
      left: '50%',
      maxWidth: '95%',
      width: 'fit-content',
      maxHeight: '80%',
      height: 'fit-content',
      backgroundColor: '#e9ecef',
      transform: 'translate(-50%, -50%)',
      borderRadius: '5px',
      overflow: 'auto',
      zIndex: '10',
      padding: 0,
    },
  };
  return (
    <ReactModal isOpen={showModal} style={customStyles} ariaHideApp={false}>
      <Container>
        <X
          color="grey"
          size={30}
          onClick={onHide}
          style={{
            zIndex: '10',
            position: 'absolute',
            right: 0,
            cursor: 'pointer',
          }}
        />
        {children}
        <Footer>
          <LoadingButton onClick={onClick}>Estampar</LoadingButton>
        </Footer>
      </Container>
    </ReactModal>
  );
};

export default Modal;
