import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import Spinner from '../FullSpinner';
import SignForm from './SignForm';
import { SignatoryContainer } from './styles';
import { downloadPdfFile } from '../../lib/utils/fileHandling';
import { SuccessSignment } from '../SuccsessSignment';

const StandaloneSigner = () => {
  const [loading, setLoading] = useState(false);
  const [signedDocument, setSignedDocument] = useState(null);
  const [successSigned, setSuccessSigned] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    if (signedDocument) {
      // const timerId = setTimeout(() => {
      setSuccessSigned(true);
      setLoading(false);
      // }, 2000);
      // return () => {
      //   clearTimeout(timerId);
      // };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedDocument]);

  return (
    <SignatoryContainer>
      {loading && <Spinner />}
      {successSigned ? (
        <SuccessSignment
          onClick={() => {
            setSuccessSigned(false);
          }}
        />
      ) : (
        <div
          className="sign-form"
          style={{
            visibility: loading ? 'hidden' : 'initial',
            overflowY: 'auto',
          }}
        >
          <SignForm
            onError={err => {
              alert.error(err, { timeout: 2000 });
            }}
            onLoading={setLoading}
            onDocumentSigned={(signedDocument, document) => {
              setSignedDocument(signedDocument);
              downloadPdfFile(signedDocument, '_signed', document.meta.name);
            }}
          />
        </div>
      )}
    </SignatoryContainer>
  );
};

export default StandaloneSigner;
