import React from "react";

const SuscriboLogo = () => {
  return (
    <svg
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 292 117"
    >
      <g  transform="translate(-30, 0)">
        <path
          d="M29.22,63l5.37-6.44c3.72,3.07,7.62,5,12.35,5,3.72,0,6-1.47,6-3.9V57.6c0-2.31-1.42-3.49-8.33-5.26-8.33-2.13-13.7-4.43-13.7-12.64v-.12c0-7.5,6-12.46,14.47-12.46A24,24,0,0,1,60.7,32.38L56,39.23c-3.66-2.54-7.26-4.08-10.75-4.08s-5.31,1.6-5.31,3.6v.12c0,2.72,1.77,3.61,8.91,5.44,8.39,2.18,13.12,5.19,13.12,12.4v.12c0,8.21-6.26,12.82-15.18,12.82A26.31,26.31,0,0,1,29.22,63Z"
          fill="white"
        />
        <path
          d="M65.66,51.33V27.71h9.1V51.1c0,6.73,3.36,10.22,8.92,10.22S92.6,58,92.6,51.39V27.71h9.09V51c0,12.52-7,18.66-18.13,18.66S65.66,63.5,65.66,51.33Z"
          fill="white"
        />
        <path
          d="M104.59,63,110,56.59c3.73,3.07,7.62,5,12.35,5,3.72,0,6-1.47,6-3.9V57.6c0-2.31-1.42-3.49-8.33-5.26-8.33-2.13-13.71-4.43-13.71-12.64v-.12c0-7.5,6-12.46,14.47-12.46a23.91,23.91,0,0,1,15.36,5.26l-4.72,6.85c-3.67-2.54-7.27-4.08-10.75-4.08s-5.32,1.6-5.32,3.6v.12c0,2.72,1.77,3.61,8.92,5.44,8.39,2.18,13.11,5.19,13.11,12.4v.12c0,8.21-6.26,12.82-15.18,12.82A26.31,26.31,0,0,1,104.59,63Z"
          fill="white"
        />
        <path
          d="M139.86,48.5v-.12c0-11.75,8.85-21.38,21.55-21.38,7.8,0,12.47,2.6,16.31,6.38l-5.79,6.67c-3.19-2.89-6.44-4.66-10.58-4.66-7,0-12,5.79-12,12.87v.12c0,7.09,4.91,13,12,13,4.73,0,7.62-1.89,10.87-4.85L178,62.38c-4.25,4.55-9,7.38-16.95,7.38A20.86,20.86,0,0,1,139.86,48.5Z"
          fill="white"
        />
        <path
          d="M181.74,27.71h18.9c5.25,0,9.33,1.48,12.05,4.19a13,13,0,0,1,3.54,9.45v.12c0,6.68-3.6,10.87-8.86,12.82l10.1,14.76H206.84L198,55.82h-7.15V69.05h-9.09Zm18.31,20.08c4.43,0,7-2.36,7-5.85v-.11c0-3.9-2.72-5.91-7.15-5.91h-9V47.79Z"
          fill="white"
        />
        <path
          d="M222.49,27.71h9.1V69.05h-9.1Z"
          fill="white"
        />
        <path
          d="M239.57,27.71h19.19c4.73,0,8.45,1.3,10.81,3.66a9.48,9.48,0,0,1,2.84,7v.12c0,4.66-2.49,7.26-5.44,8.92,4.79,1.83,7.74,4.6,7.74,10.16v.11c0,7.56-6.14,11.34-15.48,11.34H239.57Z"
          fill="white"
        />
        <path
          
          d="M276.84,48.5v-.12c0-11.75,9.27-21.38,22-21.38s21.91,9.51,21.91,21.26v.12c0,11.76-9.27,21.38-22,21.38S276.84,60.25,276.84,48.5Zm34.44,0v-.12c0-7.09-5.2-13-12.53-13s-12.4,5.79-12.4,12.87v.12c0,7.09,5.2,13,12.52,13S311.28,55.59,311.28,48.5Z"
          fill="white"
        />
        <rect
          x="245.79"
          y="44.76"
          width="3.07"
          height="15.53"
          transform="translate(299.85 -194.81) rotate(90)"
          fill="#284c61"
        />
        <rect
          x="245.79"
          y="50.34"
          width="3.07"
          height="15.53"
          transform="translate(305.44 -189.22) rotate(90)"
          fill="#284c61"
        />
        <rect
          x="245.79"
          y="55.85"
          width="3.07"
          height="15.53"
          transform="translate(310.95 -183.71) rotate(90)"
          fill="#284c61"
        />
        <polygon
          points="259.88 60.96 259.88 55.13 263.41 58.03 259.88 60.96"
          fill="#284c61"
        />
        <path
          strokeWidth="0.22px"
          d="M65.66,89.2v0a5.94,5.94,0,0,1,5.9-6.1A6.25,6.25,0,0,1,76.15,85l-.63.64a5.4,5.4,0,0,0-4-1.74,5,5,0,0,0-5,5.28v0a5.09,5.09,0,0,0,5,5.31,5.42,5.42,0,0,0,4.07-1.83l.59.57a6.24,6.24,0,0,1-4.69,2.06A5.87,5.87,0,0,1,65.66,89.2Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M78.09,89.2v0a5.94,5.94,0,1,1,11.87,0s0,0,0,0a5.94,5.94,0,1,1-11.87,0Zm11,0v0a5.11,5.11,0,0,0-5-5.31,5.05,5.05,0,0,0-5,5.28v0a5.11,5.11,0,0,0,5,5.31A5,5,0,0,0,89.05,89.2Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M93.27,83.26h.83l8.14,10.27V83.26h.84v11.8h-.66L94.11,84.58V95.06h-.84Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M110.19,84.07h-4.12v-.81h9.1v.81h-4.11v11h-.87Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M118.17,83.26h5a4.63,4.63,0,0,1,3.32,1.17,3.09,3.09,0,0,1,.9,2.19v0c0,2-1.47,3.1-3.46,3.37l3.9,5h-1.12l-3.76-4.89H119v4.89h-.87ZM123,89.38c2,0,3.44-1,3.44-2.69v0c0-1.58-1.25-2.58-3.39-2.58h-4v5.31Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M135.17,83.18H136l5.5,11.88h-1l-1.5-3.3h-7l-1.5,3.3h-.91Zm3.5,7.79-3.1-6.83L132.45,91Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M146.08,84.07H142v-.81h9.1v.81H147v11h-.88Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M152.61,89.2v0a5.94,5.94,0,0,1,6-6.1,5.88,5.88,0,0,1,5.91,6.07s0,0,0,0a5.94,5.94,0,1,1-11.87,0Zm11,0v0a5.11,5.11,0,0,0-5-5.31,5.05,5.05,0,0,0-5,5.28v0a5.11,5.11,0,0,0,5,5.31A5,5,0,0,0,163.57,89.2Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M167,93.35l.57-.66a5.76,5.76,0,0,0,4.23,1.75c1.77,0,3-1,3-2.34v0c0-1.24-.66-2-3.34-2.51-2.83-.57-4-1.53-4-3.32v0c0-1.76,1.6-3.11,3.79-3.11a5.91,5.91,0,0,1,4,1.44l-.56.69a5.07,5.07,0,0,0-3.52-1.33c-1.74,0-2.88,1-2.88,2.22v0c0,1.24.64,2,3.44,2.58s3.87,1.53,3.87,3.25v0c0,1.91-1.63,3.22-3.91,3.22A6.7,6.7,0,0,1,167,93.35Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M184.22,83.26h3.92c3.71,0,6.27,2.55,6.27,5.87v0a5.91,5.91,0,0,1-6.27,5.9h-3.92Zm3.92,11a5,5,0,0,0,5.36-5v0a5,5,0,0,0-5.36-5.09h-3V94.25Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M197.84,83.26h.88v11.8h-.88Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M202.15,89.2v0a5.9,5.9,0,0,1,5.84-6.1,6.16,6.16,0,0,1,4.31,1.55l-.57.66a5.32,5.32,0,0,0-3.79-1.42,5,5,0,0,0-4.88,5.28v0c0,3,1.91,5.33,5.06,5.33a6,6,0,0,0,3.76-1.35V89.75h-3.94v-.81h4.78V93.5a7.1,7.1,0,0,1-4.63,1.77A5.75,5.75,0,0,1,202.15,89.2Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M216.34,83.26h.87v11.8h-.87Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M224.44,84.07h-4.11v-.81h9.1v.81h-4.11v11h-.88Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M235.39,83.18h.85l5.49,11.88h-1l-1.5-3.3h-7l-1.5,3.3h-.91ZM238.9,91l-3.1-6.83L232.68,91Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M244.55,83.26h.88v11h6.93v.81h-7.81Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M255.32,83.26h8.39v.81h-7.52v4.64H263v.81h-6.76v4.73h7.6v.81h-8.47Z"
          fill="white"
        />
        <path
          strokeWidth="0.22px"
          d="M266.18,93.35l.57-.66A5.76,5.76,0,0,0,271,94.44c1.77,0,3-1,3-2.34v0c0-1.24-.66-2-3.34-2.51s-4-1.53-4-3.32v0c0-1.76,1.6-3.11,3.79-3.11a5.91,5.91,0,0,1,4.05,1.44l-.56.69a5.07,5.07,0,0,0-3.52-1.33c-1.74,0-2.88,1-2.88,2.22v0c0,1.24.64,2,3.44,2.58s3.88,1.53,3.88,3.25v0c0,1.91-1.64,3.22-3.92,3.22A6.74,6.74,0,0,1,266.18,93.35Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default SuscriboLogo;
