import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAlert } from 'react-alert';
import Spinner from '../FullSpinner';
import { Button, SignatoryContainer } from './styles';
import {
  configureOrganization,
  listOrganizations,
  getOrganization,
} from '../../services/monaxService';
import { cancelAblePromise } from '../../services/axiosCall';
import LoadingButton from '../LoadingButton';

const GENERIC_ERROR = 'Algo salió mal. Intente nuevamente más tarde';

const MonaxConfigurer = () => {
  const [error, setError] = useState('');
  const [valueOrganizations, setOrganizations] = useState(null);
  const alert = useAlert();

  const {
    isLoading: authLoading,
    isAuthenticated,
    error: authError,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      let cPr = cancelAblePromise(listOrganizations(getAccessTokenSilently));
      let cPrs = [];
      cPr.promise.then(
        async organizations => {
          const orgList = await organizations.filter(
            ({ isApprover }) => isApprover
          );
          cPrs = orgList.map(({ address }) =>
            cancelAblePromise(getOrganization(getAccessTokenSilently, address))
          );
          const orgs = await Promise.all(cPrs.map(cp => cp.promise));
          setOrganizations(orgs);
        },
        () => setError(GENERIC_ERROR)
      );
      return () => {
        cPr.cancel();
        cPrs.forEach(cp => cp.cancel());
      };
    }
    // else {
    //   loginWithRedirect({ appState: { agreement: null } });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (authLoading) {
    return (
      <SignatoryContainer>
        <Spinner />
      </SignatoryContainer>
    );
  }

  // useEffect(() => {
  //   effect
  //   return () => {
  //     cleanup
  //   }
  // }, [input])
  return (
    <>
      <SignatoryContainer>
        {!isAuthenticated && (
          <Button
            type="button"
            onClick={() => loginWithRedirect({ appState: { agreement: null } })}
          >
            Autenticarse
          </Button>
        )}
        {(error || authError) && (
          <div className="mt-3 d-flex justify-content-center">
            <span className="alert alert-danger text-muted">
              {error || authError.message}
            </span>
          </div>
        )}
        {isAuthenticated && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100vh',
                alignItems: 'center',
              }}
            >
              {valueOrganizations &&
                valueOrganizations.map(org => (
                  <div
                    key={org.address}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span>
                      <b>{org.name}</b> MONAX organization
                    </span>
                    <span>
                      Redirects to: <b>{org.signingURL}</b>
                    </span>
                    <LoadingButton
                      disabled={org.signingURL === window.location.origin}
                      className="btn btn-link"
                      type="button"
                      onClick={() =>
                        configureOrganization(
                          getAccessTokenSilently,
                          org,
                          `${window.location.origin}/callback`
                        ).then(
                          () =>
                            alert.success(
                              `${org.name} configured for Suscribo`,
                              { timeout: 2000 }
                            ),
                          () =>
                            alert.error(`${org.name} configuration failed`, {
                              timeout: 2000,
                            })
                        )
                      }
                    >
                      Configure
                    </LoadingButton>
                  </div>
                ))}
            </div>
            <div className="logout">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  logout({ returnTo: window.location.origin });
                }}
              >
                Salir
              </button>
            </div>
          </>
        )}
      </SignatoryContainer>
    </>
  );
};

export default MonaxConfigurer;
