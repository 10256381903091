import styled from 'styled-components';

export const Title = styled.h4`
  font-family: 'CeraM';
  padding: 0 10px;
  font-size: 18px;
  text-align: center;
  margin: 0;
`;

export const Button = styled.button`
  margin-top: 20px;
  background-color: #294d61;
  font-weight: 600;
  padding: 10px 40px;
  border: none;
  color: white;
  border-radius: 25px;
  font-family: 'CeraM';
  width: fit-content;
  &:focus {
    outline: none;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 30px 50px;
  border-radius: 8px;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.4);
`;
