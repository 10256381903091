import axios from 'axios';
import { ClientError } from '../lib/utils/exceptions';
import { API_URL } from '../config';

const GENERIC_ERROR = 'Ha ocurrido un error. Consulte con el administrador';

const axiosInstance = axios.create({
  headers: {},
  validateStatus: status => status < 400,
});

const axiosInstanceFile = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  validateStatus: status => status < 400,
});

const encodeQueryParams = (url, query) => {
  const encodeURL = new URL(url);
  // ToDo: Have to agree how to encode null
  if (query) {
    Object.entries(query).forEach(([k, v]) =>
      encodeURL.searchParams.append(k, v)
    );
  }
  return encodeURL;
};

const callAxiosInstanceFile = async (url, query, requestOptions) => {
  return axiosInstanceFile({
    url: encodeQueryParams(`${API_URL}${url}`, query).toString(),
    data: requestOptions.body,
    ...requestOptions,
  });
};

const callAxiosInstance = async (url, query, requestOptions) => {
  return axiosInstance({
    url: encodeQueryParams(`${API_URL}${url}`, query).toString(),
    data: requestOptions.body,
    ...requestOptions,
  });
};

const axiosCall = async (
  url,
  { query, ...requestOptions },
  clause = false,
  file = true
) => {
  try {
    let response;
    if (file) {
      response = await callAxiosInstanceFile(url, query, requestOptions);
    } else {
      response = await callAxiosInstance(url, query, requestOptions);
    }
    if (response.status >= 200 && response.status < 400) {
      return response;
    }
    throw new Error('Unhandled Conditions');
  } catch (error) {
    if (error && error.response && error.response.status < 500) {
      console.error('Axios error=', error);
      throw new ClientError(error.response.data.message, error.response.status);
    } else {
      console.error('Axios error=', error);
      throw new Error(GENERIC_ERROR);
    }
  }
};

export const unAuthAxiosCall = (url, requestOptions, clause, file = true) =>
  axiosCall(url, requestOptions, clause, file);

export function cancelAblePromise(prom) {
  const obj = {
    promise: new Promise((resolve, reject) => {
      prom.then(
        (res) => {
          if (!obj.canceled) {
            resolve(res);
          }
        },
        (err) => {
          if (!obj.canceled) {
            reject(err);
          }
        }
      );
    }),
    canceled: false,
    cancel: () => {
      const hasChanged = !obj.canceled;
      obj.canceled = true;
      return { canceled: obj.canceled, hasChanged };
    },
  };
  return obj;
}