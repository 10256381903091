import React, { useState } from 'react';
import {
  AlertIcon,
  Background,
  Modal,
  Start,
  TextBox,
  TextBoxTitle,
  Title,
} from './style';
import alertIcon from '../../resources/svgs/alert.svg';

interface IWarningPopupProps {
  onClick: any;
}

export const WarningPopup = ({ onClick }: IWarningPopupProps) => {
  const [showAlert, setShowAlert] = useState(false);
  const handleCheckbox = (e: any) => {
    setShowAlert(e.target.checked);
  };
  const onSubmit = () => {
    if (showAlert) {
      localStorage.setItem('showAlert', 'notShow');
    }
  };
  return (
    <>
      <Modal>
        <Title>
          Firma documentos utilizando el certificado de firma de su eleccion.
        </Title>
        <TextBox>
          <AlertIcon src={alertIcon} />
          <TextBoxTitle>Aviso de Seguridad</TextBoxTitle>
          SUSCRIBO Firmador electronico es un aplicativo basado en codigo
          abierto de FirmaEC. No absorbemos ni grabamos informacion del
          certificado de firma electronica, ni de su contrasena.
        </TextBox>
        <label className='CeraLight'>
          <input type="checkbox" onClick={handleCheckbox} /> No mostrar este
          mensaje de nuevo.
        </label>
        <Start
          onClick={() => {
            onSubmit();
            onClick();
          }}
        >
          Empezar
        </Start>
      </Modal>
      <Background />
    </>
  );
};
