import React, { useState, useEffect } from 'react';
import { Buffer } from 'buffer';
import pdfsigner from '../../lib/sign/signPdf';
import { validateField } from '../../lib/utils/validate';
import { getPdfSignedWithMetada } from '../../services/pdfService';
import { readFileAsBuffer } from '../../lib/utils/fileHandling';
import classnames from 'classnames';
import FileUploader from '../UploadButton';
import {
  Button,
  Input,
  InputPassword,
  SignReasonContainer,
  DocumentIcon,
} from './styles';
import documentIcon from '../../resources/svgs/document.svg';
import certificateIcon from '../../resources/svgs/certificate.svg';
import ClickAblePdf from '../ClickAblePdf';
import Modal from '../Modal';
import { useAlert } from 'react-alert';
import { WarningPopup } from '../SingWarningPopup';
const GENERIC_ERROR =
  'Ha ocurrido un error al estampar su firma. Contacte al administrador';
const WRONG_PASS_ERROR = 'Password incorrecta';

const SignForm = ({
  preloadedDocument,
  onError,
  onDocumentSigned,
  onLoading,
}) => {
  const alert = useAlert();

  const [showModal, setShowModal] = useState(false);
  const [certData, setCertData] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [needPassword, setNeedPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [wrongPass, setWrongPass] = useState('');
  const [certFileName, setCertFileName] = useState('');
  const [coordinates, setCoordinates] = useState(null);
  const [signReason, setSignReason] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [document, setDocument] = useState(null);
  const [warningPopup, setWarningPopup] = useState(true);

  const processDocument = async (file) => {
    console.log('file', file);
    if (!file) {
      setDocument(null);
      return;
    }
    const [signaturesByPage, mediaBox] = await Promise.all([
      pdfsigner.getSignaturesCoordinates(file.file),
      pdfsigner.getMediaBox(file.file),
    ]);
    console.log('signaturesByPage', signaturesByPage);
    setDocument({
      type: file.type,
      ...file,
      signatures: signaturesByPage.filter(([page, coords]) => coords.length !== 0).map(([page, coordinates]) => ({page, coordinates})),
      mediaBox,
    });
  };

  useEffect(() => {
    processDocument(preloadedDocument);
  }, [preloadedDocument]);

  const certDataError = validateField(certData, {
    presence: {
      message: '^Debe de cargar el certificado',
      allowEmpty: false,
    },
  });
  const passError = validateField(password, {
    presence: {
      message: '^Debe de ingresar una password',
      allowEmpty: false,
    },
  });
  const documentError = validateField(document, {
    presence: {
      message: '^Debe de cargar un archivo a firmar',
      allowEmpty: false,
    },
  });

  const handleStamp = async () => {
    if (!coordinates) {
      alert.error('Debe seleccionar donde estampar su firma.', {
        timeout: 2000,
      });
      return;
    }
    setWrongPass('');
    if (needPassword <= !passError) {
      let metaData;
      try {
        metaData = pdfsigner.getCertMetdata(certData, {
          passphrase: password,
        });
      } catch (error) {
        if (
          error.message ===
          'PKCS#12 MAC could not be verified. Invalid password?'
        ) {
          alert.error(WRONG_PASS_ERROR, { timeout: 2000 });
          return setWrongPass(WRONG_PASS_ERROR);
        }
        console.error('Error al obtener metadata del certificado=', error);
        onError(error.message || GENERIC_ERROR);
      }

      onLoading(true);
      let res;
      try {
        let blob = document.file;
        // ToDo: This normalization must be done on file creation instead...
        if (blob.type !== 'application/pdf') {
          const file = Buffer.from(blob);
          blob = new Blob([file.buffer], { type: 'application/pdf' });
        }
        const pdfDataSigned = await getPdfSignedWithMetada(
          blob,
          document.mediaBox,
          metaData,
          coordinates,
          signReason,
          `${pageNumber}`,
          document.bcMeta || JSON.stringify({})
        );
        // ToDo: Better than decode with readFileAsBuffer, make getPdfSignedWithMetada to have responseType: ArrayBuffer
        res = await readFileAsBuffer(pdfDataSigned);
      } catch (error) {
        console.error('Error al obtener metadata del servidor=', error);
        onError(error.message || GENERIC_ERROR);
      }
      onLoading(false);
      if (!res) {
        return;
      }

      try {
        const bufferPdf = Buffer.from(res);
        const signPDF = pdfsigner.sign(bufferPdf, certData, {
          passphrase: password,
        });
        const signedDocument = new Blob([signPDF], { type: 'application/pdf' });
        setSubmitted(false);
        setShowModal(false);
        onDocumentSigned(signedDocument, document);
      } catch (error) {
        if (
          error.message ===
          'PKCS#12 MAC could not be verified. Invalid password?'
        ) {
          alert.error(WRONG_PASS_ERROR, { timeout: 2000 });
          return setWrongPass(WRONG_PASS_ERROR);
        }
        console.error('Error al firmar=', error);
        onError(error.message || GENERIC_ERROR);
      }
    }
  };

  useEffect(() => {
    const showAlert = localStorage.getItem('showAlert');
    setWarningPopup(showAlert !== 'notShow');
  }, []);

  return (
    <>
      {warningPopup && <WarningPopup onClick={() => setWarningPopup(false)} />}
      <div className="row justify-content-between">
        <div
          className={`d-flex flex-column col-sm-12 ${
            preloadedDocument ? 'col-md-12' : 'col-md-6'
          } align-items-center justify-content-start`}
        >
          <div className="my-2 ">
            <DocumentIcon uploaded={certFileName} src={certificateIcon} />
            <FileUploader
              type="certificate"
              text={certFileName ? certFileName : 'Elegir Certificado'}
              accept=".pfx,.p12"
              onChange={certificateFile => {
                if (!certificateFile[0]) {
                  return;
                }
                setCertFileName(certificateFile[0].name);
                readFileAsBuffer(certificateFile[0]).then(res => {
                  const bufferP12 = Buffer.from(res);
                  setCertData(bufferP12);
                  setNeedPassword(pdfsigner.checkHasPass(bufferP12));
                  setPassword('');
                });
              }}
            />
          </div>
          {submitted && Boolean(certDataError) && (
            <div className="mt-3 mb-5 d-flex justify-content-center">
              <span className="alert alert-danger text-muted">
                {certDataError}
              </span>
            </div>
          )}
          {needPassword && (
            <div className="mt-1 d-flex justify-content-center">
              <div>
                <InputPassword
                  className={classnames(
                    'form-control',
                    submitted &&
                      needPassword &&
                      (Boolean(passError) || Boolean(wrongPass))
                      ? 'is-invalid'
                      : ''
                  )}
                  placeholder="Insertar contraseña..."
                  type="password"
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                    setWrongPass('');
                  }}
                />
                {submitted && needPassword && Boolean(passError) && (
                  <div className="mt-3 mb-5 d-flex justify-content-center">
                    <span className="alert alert-danger text-muted">
                      {passError}
                    </span>
                  </div>
                )}
                {submitted && needPassword && Boolean(wrongPass) && (
                  <div className="mt-3 mb-5 d-flex justify-content-center">
                    <span className="alert alert-danger text-muted">
                      {wrongPass}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {!preloadedDocument && (
          <div className="d-flex flex-column col-sm-12 col-md-6 align-items-center justify-content-start">
            <div className="my-2 ">
              <DocumentIcon
                uploaded={document?.meta?.name}
                src={documentIcon}
              />
              <FileUploader
                text={
                  document?.meta?.name ? document.meta.name : 'Elegir Documento'
                }
                accept=".pdf,.Pdf,.pDf,.pdF,.PDf,.pDF,.PdF,.PDF"
                onChange={files => {
                  if (files && files[0]) {
                    processDocument({
                      file: files[0],
                      meta: { name: files[0].name },
                    });
                  }
                }}
              />
            </div>

            {submitted && Boolean(documentError) && (
              <div className="mt-3 mb-5 d-flex justify-content-center">
                <span className="alert alert-danger text-muted">
                  {documentError}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      {showModal && (
        <Modal
          style={{ overflow: 'auto' }}
          showModal={showModal}
          onHide={() => setShowModal(false)}
          title="Firmar documento"
          onClick={handleStamp}
        >
          <SignReasonContainer>
            <label
              style={{
                margin: '0',
                padding: '0',
                fontSize: '18px',
                color: '#284c61',
                whiteSpace: 'nowrap',
              }}
            >
              Razón de firma
            </label>
            <Input
              className={classnames('form-control')}
              placeholder="Ingresar razón de firma"
              type="text"
              style={{ margin: '10px 0px 10px 15px' }}
              onChange={e => setSignReason(e.target.value)}
              value={signReason}
            />
          </SignReasonContainer>

          <div
            style={{
              height: '55vh',
              width: '100%',
              padding: '0 40px',
              margin: '10px 0 10px 0',
            }}
          >
            <ClickAblePdf
              file={document.file}
              initialStamps={document.signatures}
              // initialStamps={[
              //   {
              //     page: 1,
              //     coordinates: [
              //       [0, 0],
              //       [50, 50],
              //       [150, 150],
              //     ],
              //   },
              //   {
              //     page: 3,
              //     coordinates: [
              //       [50, 50],
              //       [150, 150],
              //       [250, 150],
              //     ],
              //   },
              // ]}
              pdfMediaBox={document.mediaBox}
              onChangeCoordinates={setCoordinates}
              onChangePageNumber={pageNumber =>
                pageNumber && setPageNumber(pageNumber)
              }
              bodyStyle={{
                overflow: 'scroll',
                width: 'fit-content',
                maxWidth: '100%',
                height: '45vh',
              }}
              footerStyle={{
                height: '10vh',
              }}
            />
          </div>
        </Modal>
      )}

      <div className="d-flex justify-content-center mt-4">
        <Button
          type="button"
          id="firmarBtn"
          onClick={() => {
            setSubmitted(true);
            const isValidData =
              !certDataError && !documentError && needPassword <= !passError;
            isValidData && setShowModal(true);
          }}
        >
          FIRMAR
        </Button>
      </div>
    </>
  );
};

export default SignForm;
