import axios from 'axios';

import { MONAX_BASE_URL, MONAX_AUDIENCE } from '../config';

const getAuth = async getAccessTokenSilently => {
  const token = await getAccessTokenSilently({
    audience: MONAX_AUDIENCE,
    scope: 'signing',
  });
  return token;
};

export const listOrganizations = async auth => {
  try {
    const authToken = await getAuth(auth);
    let response = await axios({
      url: `${MONAX_BASE_URL}/organizations?approver=false`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      method: 'GET',
    });
    return response.data;
  } catch (error) {
    console.error('Error retrieving documents=', error);
    throw new Error(error);
  }
};

export const getOrganization = async (auth, orgAddress) => {
  try {
    const authToken = await getAuth(auth);
    let response = await axios({
      url: `${MONAX_BASE_URL}/organizations/${orgAddress}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      method: 'GET',
    });
    return response.data;
  } catch (error) {
    console.error('Error retrieving documents=', error);
    throw new Error(error);
  }
};

export const configureOrganization = async (auth, org, signingURL) => {
  try {
    const authToken = await getAuth(auth);
    let response = await axios({
      url: `${MONAX_BASE_URL}/organizations/${org.address}/settings`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      data: JSON.stringify({
        signingURL,
        name: org.name,
      }),
    });
    return response.data;
  } catch (error) {
    console.error('Error retrieving documents=', error);
    throw new Error(error);
  }
};

export const getMonaxDocuments = async (
  auth,
  agreementId,
  organizationAddress
) => {
  try {
    const authToken = await getAuth(auth);
    // 1. Get the agreement
    let response = await axios({
      url: `${MONAX_BASE_URL}/organizations/${organizationAddress}/agreements/${agreementId}?blockstamp=true`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      method: 'GET',
    });

    const {
      documents,
      parameters,
      signaturesFileReference,
      bcMeta,
    } = response.data.agreement;

    // ToDo: handle multiple docs
    const versions = documents.documents[0].versions;
    const grant = versions[versions.length - 1].grant;
    // const grant = versions[0].grant;
    const key = documents.documents[0].key;
    const version = documents.documents[0].versions.length;

    if (!grant || !grant.length) {
      throw new Error('Could not get grant from documents');
    }

    // 2. Get the document
    response = await axios({
      url: `${MONAX_BASE_URL}/organizations/${organizationAddress}/documents/${encodeURIComponent(grant)}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      method: 'GET',
    });
    let file = response.data;

    // 3. Get the metadata
    response = await axios({
      url: `${MONAX_BASE_URL}/organizations/${organizationAddress}/documents/${encodeURIComponent(
        grant
      )}/info?meta=true`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    let { meta } = response.data;
    meta.name = meta.name.replace(/\.[^/.]+$/, '');

    // 3. If file is not PDF assemble it
    if (response.headers['content-type'] !== 'application/pdf') {
      response = await axios({
        url: `${MONAX_BASE_URL}/organizations/${organizationAddress}/documents/assemble`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        data: JSON.stringify({
          parameters,
          grants: [grant],
          signaturesFileReference,
        }),
      });
      file = response.data.docs[0].data;
    }

    return {
      file: new Blob([new Uint8Array(file.data)], { type: 'application/pdf' }),
      type: 'application/pdf',
      meta,
      key,
      version,
      bcMeta,
    };
  } catch (error) {
    console.error('Error retrieving documents=', error);
    throw new Error(error);
  }
};

export const sendMonaxSignedDocument = async (
  auth,
  agreementId,
  organizationAddress,
  document,
  key,
  meta,
  versionIndex
) => {
  try {
    const authToken = await getAuth(auth);

    const formData = new FormData();
    formData.append('file', document, meta.name);

    let response = await axios({
      url: `${MONAX_BASE_URL}/organizations/${organizationAddress}/documents`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      method: 'POST',
      data: formData,
    });
    const grant = response.data.grant;
    if (!grant) {
      throw new Error('Could not get grant for document');
    }

    return axios({
      url: `${MONAX_BASE_URL}/organizations/${organizationAddress}/agreements/${agreementId}/documents/${key}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      data: JSON.stringify({
        grant,
        version: versionIndex,
      }),
    });
  } catch (error) {
    console.error('Error sending document=', error);
    throw new Error(error);
  }
};

export const signMonaxDocument = async (
  auth,
  agreementId,
  organizationAddress,
  signature,
  ownerName
) => {
  try {
    const authToken = await getAuth(auth);

    const formData = new FormData();
    formData.append('file', signature, ownerName);

    let response = await axios({
      url: `${MONAX_BASE_URL}/organizations/${organizationAddress}/documents`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      method: 'POST',
      data: formData,
    });
    const grant = response.data.grant;
    if (!grant) {
      throw new Error('Could not get grant for document');
    }

    return axios({
      url: `${MONAX_BASE_URL}/organizations/${organizationAddress}/agreements/${agreementId}/sign`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      data: JSON.stringify({
        grant,
        name: ownerName,
        signatureType: 'document',
      }),
    });
  } catch (error) {
    console.error('Error signing document=', error);
    throw new Error(error);
  }
};
