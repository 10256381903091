import React from 'react';
import { Container, Header, Content, Footer } from './styles';
import SuscriboLogo from '../SuscriboLogo';

const Layout = ({ children }) => {
  return (
    <Container>
      <Header>
        <SuscriboLogo />
      </Header>
      <Content>{children}</Content>
      <Footer>
        <a
          href="https://suscribo.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          www.suscribo.com
        </a>
      </Footer>
    </Container>
  );
};

export default Layout;
