import validatejs from 'validate.js';

validatejs.validators.custom = function(value, options) {
  if (!options.checker(value)) {
    return options.message;
  }
  return true;
};

export function validateField(field, rule) {
  const vv = validatejs.validate(
    {value: field},
    {
      value: rule,
    },
  );
  return !vv ? null : vv.value[0];
}
