import React, {useState} from 'react';
import {Button} from "../Modal/styles";
import Spinner from "../Spinner";

const LoadingButton = (props) => {
  const [valueLoading, setLoading] = useState(false);
  return (
    <Button
      disabled={valueLoading || props.disabled}
      {...props}
      onClick={(...pp) => {
        const res = props.onClick(...pp);
        if (res instanceof Promise) {
          setLoading(true);
          return res.then(
            (rr) => {
              setLoading(false);
              return rr;
            },
            (err) => {
              setLoading(false);
              throw err;
            }
          );
        } else {
          return res;
        }
      }}
    >
      {valueLoading ? <Spinner/> : props.children}
    </Button>
  );
};

export default LoadingButton;
