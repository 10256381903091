import forge from 'node-forge';
import SignPdfError from './signPdfError';
import {readFileAsString} from "../utils/fileHandling";

function findLastIndexOf(buff, part) {
  let indexPos = buff.indexOf(part);
  let res = -1;
  while (indexPos !== -1) {
    res = indexPos;
    indexPos = buff.indexOf(part, indexPos + 1);
  }
  return res;
}

function assert(condition, msg) {
  if (!condition) {
    throw new Error(`assertion error: ${msg}`);
  }
}

function findDelimitedBy(start, end, file) {
  const ss = file.indexOf(start);
  if (ss === -1) {
    return [
      null,
      -1,
    ];
  }
  const ee = file.indexOf(end, ss);
  assert(ee !== -1, 'corrupted file. No delim by');
  return [
    file.slice(ss + start.length, ee),
    ee + end.length,
  ];
}

function findTag(tag, file) {
  const ss = findLastIndexOf(file, tag);
  if (ss === -1) {
    return [
      [],
      -1
    ];
  }
  // ToDo: Expect whitespaces between tag and parentesis
  const ps = file.indexOf('[', ss);
  assert(ps !== -1, 'corrupted file. Params start not found');
  const pe = file.indexOf(']', ps);
  assert(pe !== -1, 'corrupted file. Params end not found');
  return [
    file.slice(ps + 1, pe).trim().split(/\s+/),
    pe + 1,
  ];
}

function findTagWoArgs(tag, file) {
  const mm = tag.exec(file);
  if (!mm) {
    return -1;
  }
  return mm.index;
}

function findAllTags(tag, file) {
  const tags = [];
  let [arg, pe] = findTag(tag, file);
  while (pe !== -1) {
    tags.push([arg, pe]);
    [arg, pe] = findTag(tag, file.slice(pe));
  }
  return tags;
}

function findObj(refNum, file) {
  const tt = `${refNum} 0 obj\n`;
  return findDelimitedBy(tt, '\nendobj', file);
}

function findCompoundObj(refNum, file) {
  let compound = null;
  let rFile = file;
  let rr = findObj(refNum, rFile);
  while(rr[1] !== -1) {
    compound = compound !== null ? `${compound}\n${rr[0]}` : rr[0];
    rFile = rFile.slice(rr[1]);
    rr = findObj(refNum, rFile);
  }
  return compound;
}

function reverseStr(str) {
  return Array.from(str).reverse().join('');
}

const START_OBJ_INV_RE = /\s+jbo\s+0\s+(\d+)\s+/;

function findEnclosingObj(at, file) {
  const objEnd = file.indexOf(`\nendobj`, at);
  const head = file.slice(0, objEnd);
  const rr = reverseStr(head);
  const mm = START_OBJ_INV_RE.exec(rr);
  assert(mm, 'Corrupt file, cannot find object at position')
  const objRef = reverseStr(mm[1]);
  const startR = mm.index;
  const startObj = head.length - startR;
  return [startObj, objEnd, objRef];
}

export class SignPdf {
  async getMediaBox(pdf) {
    const binary = await readFileAsString(pdf);
    // ToDo: Check no /MediaBox substr match
    // ToDo: Each page has its media box
    const mediaBoxStart = binary.indexOf('/MediaBox');
    if (mediaBoxStart === -1) {
      throw new Error('MediaBox not Found');
    }
    
    let mediaBox = binary
      .slice(
        binary.indexOf('[', mediaBoxStart) + 1,
        binary.indexOf(']', mediaBoxStart)
      )
      .trim()
      .split(/\s+/);

    return { width: mediaBox[2], height: mediaBox[3] };
  }

  async getSignaturesCoordinates(pdf) {
    const headBinStr = await readFileAsString(pdf);
    const pagesSpecAt = findTagWoArgs(/\/Type\s*\/Pages/, headBinStr);
    assert(pagesSpecAt !== -1, 'Corrupt file. No page specs on the file')
    const [,, pagesSpecObjRef] = findEnclosingObj(pagesSpecAt, headBinStr);
    const pagesSpecObj = findCompoundObj(pagesSpecObjRef, headBinStr);
    const [kidHeadArgs, ] = findTag('/Kids', pagesSpecObj);
    const pageObjIds = new Array(kidHeadArgs.length / 3).fill(null).map((a, ind) => kidHeadArgs[ind * 3]);
    return pageObjIds.map(
      (pageObjId, pageIndex) => {
        const pageNum = pageIndex + 1;
        const pageObj = findCompoundObj(pageObjId, headBinStr);
        const [annotArgs, annotFoundAt] = findTag('/Annots', pageObj);
        if (annotFoundAt === -1) {
          return null;
        }
        const sigAnnotObjIds = new Array(annotArgs.length / 3).fill(null).map((a, ind) => annotArgs[ind * 3]);
        return [
          pageNum,
          sigAnnotObjIds.map(
            sigAnnotObjId => {
              const sigAnnotObj = findCompoundObj(sigAnnotObjId, headBinStr);
              // ToDo: Should not only look for Rect, but for Rect inside 132/Type/Annot/FT/Sig header!
              const [rectArgs, rectFoundAt] = findTag('/Rect', sigAnnotObj);
              if (rectFoundAt === -1) {
                return null;
              }
              return [rectArgs[0], rectArgs[1]];
            }
          ),
        ];
      }
    ).filter(
      (aa) => aa !== null
    );
  }

  getTmpPdf(pdf) {
    // ToDo: Improve Header detection. Should match /ByteRange[ too (no whitespace)
    const BYTERANGE_TAG = '/ByteRange [';
    const byteRangePos = findLastIndexOf(pdf, BYTERANGE_TAG);
    if (byteRangePos === -1) {
      throw new SignPdfError(
        `Could not find ByteRange placeholder: ${byteRangePos}`,
        SignPdfError.TYPE_PARSE
      );
    }
    // ToDo: Improve Header detection. Should match wo whitespace too
    const byteRangeEND = pdf.indexOf(' ]', byteRangePos);
    const byteRangeArray = pdf.slice(byteRangePos + BYTERANGE_TAG.length, byteRangeEND + 1);

    let str = new TextDecoder('utf-8').decode(byteRangeArray);
    // ToDo: Split and trim
    let arr = str.split(' ');
    const offset1 = parseInt(arr[0], 10); // L1 = Length 1 (Offet 1)
    const contentLengthBeforeSign = parseInt(arr[1], 10); // L1 = Length 1 (Content length before signature)
    const signIndexEnd = parseInt(arr[2], 10); //  O2 = offset 2 (L1 + signature length)
    const contentLengthAfterSign = parseInt(arr[3], 10); //  L2 = Length 2 (Content length after signature)

    const contentSign = pdf.slice(
      contentLengthBeforeSign + 1,
      signIndexEnd - 1
    );

    // Place it in the document.
    const pdfTmp = Buffer.concat([
      pdf.slice(offset1, contentLengthBeforeSign),
      pdf.slice(signIndexEnd, signIndexEnd + contentLengthAfterSign),
    ]);
    return [contentSign, contentLengthBeforeSign, signIndexEnd, pdfTmp];
  }

  checkHasPass(p12Buffer) {
    const options = {
      asn1StrictParsing: false,
      passphrase: '',
    };
    const forgeCert = forge.util.createBuffer(p12Buffer.toString('binary'));
    const p12Asn1 = forge.asn1.fromDer(forgeCert);
    try {
      forge.pkcs12.pkcs12FromAsn1(
        p12Asn1,
        options.asn1StrictParsing,
        options.passphrase
      );
      return false;
    } catch (error) {
      if (
        error.message === 'PKCS#12 MAC could not be verified. Invalid password?'
      ) {
        return true;
      }
      throw error;
    }
  }

  sign(pdfBuffer, p12Buffer, additionalOptions = {}) {
    const options = {
      asn1StrictParsing: false,
      passphrase: '',
      ...additionalOptions,
    };

    if (!(pdfBuffer instanceof Buffer)) {
      throw new SignPdfError(
        'PDF expected as Buffer.',
        SignPdfError.TYPE_INPUT
      );
    }
    if (!(p12Buffer instanceof Buffer)) {
      throw new SignPdfError(
        'p12 certificate expected as Buffer.',
        SignPdfError.TYPE_INPUT
      );
    }
    let pdf = pdfBuffer;
    const [
      contentSign,
      contentLenghtBeforeSign,
      signIndexEnd,
      pdfTmp,
    ] = this.getTmpPdf(pdf);

    let { signature, raw } = this.signFile(p12Buffer, options, pdfTmp);

    signature += Buffer.from(
      String.fromCharCode(0).repeat(contentSign.length / 2 - raw.length)
    ).toString('hex');

    pdf = Buffer.concat([
      pdf.slice(0, contentLenghtBeforeSign),
      Buffer.from(`<${signature}>`),
      pdf.slice(signIndexEnd),
    ]);
    return pdf;
  }

  getCertMetdata(p12Buffer, options) {
    const forgeCert = forge.util.createBuffer(p12Buffer.toString('binary'));
    const p12Asn1 = forge.asn1.fromDer(forgeCert);
    const p12 = forge.pkcs12.pkcs12FromAsn1(
      p12Asn1,
      options.asn1StrictParsing,
      options.passphrase
    );

    // Extract safe bags by type.
    // We will need all the certificates and the private key.

    const certBags = p12.getBags({
      bagType: forge.pki.oids.certBag,
    })[forge.pki.oids.certBag];

    let keyBags = p12.getBags({
      bagType: forge.pki.oids.pkcs8ShroudedKeyBag,
    });

    if (
      keyBags[forge.pki.oids.pkcs8ShroudedKeyBag] &&
      keyBags[forge.pki.oids.pkcs8ShroudedKeyBag].length
    ) {
      keyBags = keyBags[forge.pki.oids.pkcs8ShroudedKeyBag];
    } else {
      keyBags = p12.getBags({
        bagType: forge.pki.oids.keyBag,
      });
      if (keyBags[forge.pki.oids.keyBag].length === 0) {
        throw new Error('Unhandled conditions');
      }
      keyBags = keyBags[forge.pki.oids.keyBag];
    }

    const privateKey = keyBags[0].key;

    // Keep track of the last found client certificate.
    // This will be the public key that will be bundled in the signature.
    let certificate;
    Object.keys(certBags).forEach(i => {
      const { publicKey } = certBags[i].cert;

      // Try to find the certificate that matches the private key.
      if (
        privateKey.n.compareTo(publicKey.n) === 0 &&
        privateKey.e.compareTo(publicKey.e) === 0
      ) {
        certificate = certBags[i].cert;
      }
    });

    if (typeof certificate === 'undefined') {
      throw new SignPdfError(
        'Failed to find a certificate that matches the private key.',
        SignPdfError.TYPE_INPUT
      );
    }
    return {
      attributes: certificate.issuer.attributes,
      serialNumber: certificate.serialNumber,
      extensions: certificate.extensions,
    };
  }

  signFile(p12Buffer, options, pdf) {
    const forgeCert = forge.util.createBuffer(p12Buffer.toString('binary'));
    const p12Asn1 = forge.asn1.fromDer(forgeCert);
    const p12 = forge.pkcs12.pkcs12FromAsn1(
      p12Asn1,
      options.asn1StrictParsing,
      options.passphrase
    );

    // Extract safe bags by type.
    // We will need all the certificates and the private key.
    const certBags = p12.getBags({
      bagType: forge.pki.oids.certBag,
    })[forge.pki.oids.certBag];

    let keyBags = p12.getBags({
      bagType: forge.pki.oids.pkcs8ShroudedKeyBag,
    });
    if (
      keyBags[forge.pki.oids.pkcs8ShroudedKeyBag] &&
      keyBags[forge.pki.oids.pkcs8ShroudedKeyBag].length
    ) {
      keyBags = keyBags[forge.pki.oids.pkcs8ShroudedKeyBag];
    } else {
      keyBags = p12.getBags({
        bagType: forge.pki.oids.keyBag,
      });
      if (keyBags[forge.pki.oids.keyBag].length === 0) {
        throw new Error('Unhandled conditions');
      }
      keyBags = keyBags[forge.pki.oids.keyBag];
    }

    const privateKey = keyBags[0].key;
    // Here comes the actual PKCS#7 signing.
    const p7 = forge.pkcs7.createSignedData();
    // Start off by setting the content.
    p7.content = forge.util.createBuffer(pdf.toString('binary'));

    // Then add all the certificates (-cacerts & -clcerts)
    // Keep track of the last found client certificate.
    // This will be the public key that will be bundled in the signature.
    let certificate;
    Object.keys(certBags).forEach(i => {
      const { publicKey } = certBags[i].cert;

      p7.addCertificate(certBags[i].cert);

      // Try to find the certificate that matches the private key.
      if (
        privateKey.n.compareTo(publicKey.n) === 0 &&
        privateKey.e.compareTo(publicKey.e) === 0
      ) {
        certificate = certBags[i].cert;
      }
    });

    if (typeof certificate === 'undefined') {
      throw new SignPdfError(
        'Failed to find a certificate that matches the private key.',
        SignPdfError.TYPE_INPUT
      );
    }

    // Add a sha256 signer. That's what Adobe.PPKLite adbe.pkcs7.detached expects.
    p7.addSigner({
      key: privateKey,
      certificate,
      digestAlgorithm: forge.pki.oids.dsaWithSHA1,
      authenticatedAttributes: [
        // {
        //   type: forge.pki.oids.contentType,
        //   value: forge.pki.oids.data,
        // },
        // {
        //   type: forge.pki.oids.messageDigest,
        // },
        // {
        //   type: forge.pki.oids.signingTime,
        //   value: signDate,
        // },
      ],
    });

    // Sign in detached mode.
    p7.sign({ detached: true });

    // Check if the PDF has a good enough placeholder to fit the signature.
    const raw = forge.asn1.toDer(p7.toAsn1()).getBytes();
    let signature = Buffer.from(raw, 'binary').toString('hex');
    return { signature, raw };
  }
}

export default new SignPdf();
