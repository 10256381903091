import React from "react";
import {SpinnerContainer} from "./styles";
import * as Spn from 'react-spinkit';

const Spinner = ({props}) => (
  <SpinnerContainer className="d-flex justify-content-center">
    <Spn name="three-bounce" {...props}/>
  </SpinnerContainer>
);

export default Spinner;
