const dotenv = require('dotenv');
dotenv.config({ silent: true });
module.exports = {
  API_URL: process.env.REACT_APP_API_URL,
  MONAX_BASE_URL: process.env.REACT_APP_MONAX_BASE_URL,
  MONAX_APP_URL: process.env.REACT_APP_MONAX_APP_URL,
  MONAX_LOGIN_HOSTNAME: process.env.REACT_APP_MONAX_LOGIN_HOSTNAME,
  MONAX_AUDIENCE: process.env.REACT_APP_MONAX_AUDIENCE,
  MONAX_CLIENT_ID: process.env.REACT_APP_MONAX_CLIENT_ID,
};
