import { unAuthAxiosCall } from './axiosCall';

function getXCoordinates(x, mediaBoxX) {
  x = Math.round(x);
  x = Math.min(x, mediaBoxX);
  x = Math.max(x, 0);
  return x;
}

function getYCoordinates(y, mediaBoxY) {
  y = Math.round(y);
  y = Math.max(y, 37);
  y = Math.min(y, mediaBoxY);
  return y;
}

export const getPdfSignedWithMetada = async (
  pdfFile,
  mediaBox,
  metaData,
  coordinates,
  signReason,
  pageNumber,
  customMetaData
) => {
  const bodyFormData = new FormData();
  bodyFormData.append('pdf', pdfFile);
  bodyFormData.append('metaData', JSON.stringify(metaData));
  bodyFormData.append('signReason', signReason ? signReason : ' ');
  bodyFormData.append('customMetaData', customMetaData);
  const signPosition = {
    pageNumber: pageNumber ? pageNumber : '',
    position: {
      x: getXCoordinates(coordinates[0], mediaBox.width),
      y: getYCoordinates(coordinates[1], mediaBox.height),
    },
  };
  bodyFormData.append('signPosition', JSON.stringify(signPosition));
  const rr = await unAuthAxiosCall('/pdf/signv2', {
    method: 'POST',
    body: bodyFormData,
    responseType: 'blob',
  });
  return rr.data;
};

export const getPdfMetadataQR = async (
  metaData,
  coordinates,
  signReason,
  pageNumber,
  customMetaData
) => {
  const bodyFormData = new FormData();
  bodyFormData.append('metaData', JSON.stringify(metaData));
  bodyFormData.append('signReason', signReason ? signReason : ' ');
  bodyFormData.append('customMetaData', customMetaData);
  const signPosition = {
    pageNumber: pageNumber ? pageNumber : '',
    position: {
      x: getXCoordinates(coordinates[0]),
      y: getYCoordinates(coordinates[1]),
    },
  };
  bodyFormData.append('signPosition', JSON.stringify(signPosition));
  const rr = await unAuthAxiosCall('/pdf/qr', {
    method: 'POST',
    body: bodyFormData,
    responseType: 'blob',
  });
  return rr.data;
};
