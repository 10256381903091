import styled from 'styled-components';

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  max-width: 85%;
  width: 700px;
  height: fit-content;
  background-color: white;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  .Ceralight {
    font-family: 'CeraL';
  }
`;

export const Background = styled.div`
  left: 0;
  top: 0;
  background-color: #4b80b8;
  opacity: 50%;
  position: fixed;
  z-index: 9;
  height: 100%;
  width: 100%;
`;

export const Close = styled.div``;

export const Title = styled.h4`
  font-family: 'CeraM';
  padding: 0 10px;
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
  margin: 0;
`;

export const Start = styled.button`
  margin-top: 20px;
  background-color: #294d61;
  font-weight: 600;
  padding: 10px 40px;
  border: none;
  color: white;
  border-radius: 25px;
  font-family: 'CeraM';
  width: fit-content;
  &:focus {
    outline: none;
  }
`;

export const TextBox = styled.div`
  position: relative;
  width: 75%;
  padding: 20px;
  border: solid 1px #61a8bf;
  border-radius: 6px;
  box-shadow: -15px 15px 0px 0px #61a8bf;
  margin-top: 40px;
  margin-bottom: 25px;
  font-family: 'CeraL';
`;

export const TextBoxTitle = styled.h5`
  color: #61a8bf;
  font-family: 'CeraB';
`;

export const AlertIcon = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  transform: translate(25px, -25px);
`;
