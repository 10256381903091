import React from 'react';
import { Button, Container, Title } from './styles';
import icon from '../../resources/svgs/agreementApproved.svg';

interface IProps {
  onClick: any;
}

export const SuccessSignment = ({ onClick }: IProps) => {
  return (
    <Container>
      <Title>¡Tu documento ha sido firmado!</Title>
      <img alt="agreementApproved" style={{ marginTop: '30px' }} height="120px" src={icon} />
      <Button onClick={onClick}>Aceptar</Button>
    </Container>
  );
};
